.picture {
    @apply bg-[url('@/assets/solution_banner.jpg')];
}

.productItem {
    @apply w-[280px] pb-8;
}

.productList:after {
    width: 280px;
}
