.col-em {
    @apply flex justify-center items-center;
}

.col-content {
    @apply xl:w-[210px] xl:h-[280px] relative;
    @apply xs:w-[300px] xs:h-[300px] relative;
    @apply border border-solid border-gray-300;
    @apply flex flex-col items-center justify-center;
    @apply py-10 pb-20 mb-10;
}

.tab {
    @apply py-4 text-2xl font-medium text-black;
    @apply cursor-pointer;
}

.tab.active {
    @apply text-green-600;
    @apply transition-all ease-linear;
}

.line {
    @apply border-0 border-b-2 border-solid border-transparent;
    @apply w-0;
    @apply transition-all ease-linear;
}

.line.active {
    @apply border-green-400 w-full;
}

.product-list {
    @apply relative;
}

.product-list:after {
    content: "";
    width: 370px;
    height: 0;
}

.product-item {
    @apply w-[370px] flex flex-col items-center;
    @apply border border-gray-200 border-transparent rounded overflow-hidden;
    @apply pb-14 mb-8;
    box-shadow: 0 10px 60px 0 rgb(0, 0, 0, 0.07);
}

.product-item-four{
    @apply w-[280px] flex flex-col items-center !important;
    @apply border border-gray-200 border-transparent rounded overflow-hidden;
    @apply pb-14 mb-8;
    box-shadow: 0 10px 60px 0 rgb(0, 0, 0, 0.07);
}

.img-wrap {
    @apply w-full h-[180px] relative overflow-hidden;
    @apply before:content-[''] before:absolute before:left-0 before:top-0;
    @apply before:w-0 before:h-full before:z-10 before:bg-[rgba(27,24,37)] before:bg-opacity-30;
    @apply group-hover:before:w-1/2 before:transition-all before:ease-in before:duration-500;
    @apply after:content-[''] after:absolute after:right-0 after:top-0;
    @apply after:w-0 after:h-full after:z-10 after:bg-[rgba(27,24,37)] after:bg-opacity-30;
    @apply group-hover:after:w-1/2 after:transition-all after:ease-in after:duration-500;
}

.img {
    @apply w-full h-full align-middle cursor-pointer;
    @apply object-cover scale-105 transition-all ease-in duration-500;
    @apply group-hover:scale-100;
}

.ant-image.img .ant-image-img {
    height: 100%;
}

.product-title {
    @apply text-2xl font-black p-2.5;
    @apply group-hover:text-green-600 cursor-pointer;
}
.product-desc {
    @apply text-base px-6 indent-8 text-[#666666] line-clamp-3;
}
