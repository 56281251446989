.picture {
    @apply bg-[url('@/assets/case_banner.jpg')];
}

.caseItem {
    @apply xl:w-[370px] rounded-lg overflow-hidden;
    @apply mb-10 shadow;
    @apply transition-all ease-in-out hover:-translate-y-4 duration-300;
}

.caseImg {
    @apply w-full xl:h-[280px] xs:w-[300px] xs:h-[226px] xs:mx-auto;
    @apply mb-3;
}

.caseTitle {
    @apply text-center px-4 cursor-pointer;
}

.caseText {
    @apply text-base line-clamp-3 text-[#666] px-4;
}

.caseItem .caseLink {
    @apply mb-4 text-green-600;
    @apply hover:text-green-500;
}
