.menu {
    @apply xl:min-w-[700px];
}

.menu.ant-menu-horizontal .ant-menu-item {
    @apply px-7 text-base font-medium;
    line-height: inherit;
}

.header {
    @apply xl:flex xl:justify-between;
    @apply xl:bg-white xl:h-[120px] xl:leading-[120px];
    @apply xs:bg-black xs:bg-opacity-20 xs:h-10 xs:leading-10 xs:w-full xs:text-center;
}

.menu.ant-menu-horizontal .ant-menu-item:after {
    border-color: transparent !important;
}

.logo {
    @apply xl:w-[200px];
    @apply xs:w-[120px];
}

.menu-dropdown .ant-dropdown-menu-title-content {
    line-height: 2.5 !important;
}